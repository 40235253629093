@import "base";
@import "font";


a{
   &:hover {
    text-decoration: none;
    color: #27a3cf !important;
  }
}

$color: #27a3cf;
$bdColor: #bfbfbf;
$fontColor: #3f3a39;
body, html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#airice {
  position: relative;
  height: 100%;
  width: 100%;
}

section {
  height: 100%;

  &.screen1 {
    background: url(/static/airice/images/about/about_bg.jpg) no-repeat center 20px, linear-gradient(0deg, #395ecb 0%, #3657be 35%, #242161 75%, #1a0531 100%);
    /*透视*/
    perspective: 1200px;
    /*首页*/
    .g-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .carousel {
        width: 100%;
        height: 100%;
        display: flex;

        .carousel-inner {
          width: 100%;
          height: 100%;

          .item {
            width: 100%;
            height: 100%;

            p {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              -webkit-background-size: cover;
              background-size: cover;
              z-index: 999;

              & > video {
                width: 100%;
                height: 100%;
                object-fit: fill;
              }
            }

            .g-content-title {
              position: absolute;
              left: 50%;
              top: 50%;
              width: 520px;
              margin-left: -260px;
              height: 150px;
              margin-top: -75px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: #ffffff;

              h3 {
                font-size: 48px;
                margin-bottom: 16px;
                @media screen and (max-width: 1600px) {
                  font-size: 36px;
                }
              }

              span {
                font-size: 24px;
                @media screen and (max-width: 1600px) {
                  font-size: 18px;
                }
              }
            }

            .g-forecast {
              &::before {
                content: '解决十年后或无人种田的危机，彻底实现智能化，高效率的生态稻田。';
                position: absolute;
                left: 420px;
                top: 450px;
                width: 325px;
                font-size: 45px;
                color: #fff;
              }
            }
          }
        }
      }

      .ctrl-indicator-left {
        bottom: -85%;
      }

      .ctrl-indicator-right {
        bottom: -85%;
      }

      #play {
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -64px;
        margin-left: -64px;
        width: 128px;
        height: 128px;
        background-image: url(/static/airice/images/play.png);
        z-index: 1000;
        pointer-events: none;
      }
    }

    .g-product {
      //height: 50%;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;

      .tab-content {
        height: 100%;

        &.p-box {
          & > div {
            height: 100%;
            width: 100%;

            & > div {
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: #000;

              &.p-big {
                & > p {
                  margin-bottom: 16px;
                  font-size: 36px;
                  z-index: 1;
                }
                & > span {
                  &.p-introduce {
                    width: 550px;
                    text-align: center;
                    font-size: 24px;
                    @media screen and (max-width: 1600px) {
                      font-size: 18px;
                    }
                  }
                }
                & > div{
                  position: absolute;
                  height: 60%;
                  width: 100%;
                  background-color: rgba(0,0,0,.5);
                }
              }
            }
          }
        }
      }

      .nav {
        height: 30%;
        display: flex;
        border: 1px solid #101010;

        & > li {
          display: flex;
          flex: 1;

          & > a {
            display: flex;
            flex: 1;
            flex-direction: column;
            //justify-content: center;
            text-align: center;

            &:focus {
              background-color: transparent;
            }

            &:hover {
              background-color: transparent;
            }

            color: #000;

            & > p {
              margin-top: 50px;
            }

            & > span {
              &.p-explain {
                max-width: 300px;
                min-width: 200px;
                margin: 10px auto;
              }
            }

          }
        }
      }

      &.news-detail-banner {
        height: 60%;
      }
    }

    /*服务案例*/
    .case-content {
      .case-box {
        .case-text {
          height: 417px;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          & > h3 {
            width: 60%;
            text-align: center;
            margin-bottom: 20px;
            font-size: 32px;
            @media screen and (max-width: 1000px) {
              width: 650px;
              font-size: 24px;
            }
          }

          & > div {
            width: 900px;
            font-size: 18px;
            line-height: 40px;
            color: $fontColor;
            @media screen and (max-width: 1000px) {
              width: 650px;
              font-size: 16px;
            }
          }

          & > ul {
            width: 900px;
            margin-top: 10px;
            display: flex;
            font-size: 24px;
            justify-content: space-between;
            @media screen and (max-width: 1000px) {
              width: 650px;
              font-size: 16px;
            }

            & > li {
              & > a {
                color: #27a3cf;
              }
            }
          }
        }

        .new-text {
          width: 70%;
          margin: 0 auto;

          & > h3 {
            cursor: pointer;
            text-align: center;
            margin-bottom: 36px;
            font-size: 28px;
            font-weight: 800;
            @media screen and (max-width: 1000px) {
              width: 650px;
              font-size: 24px;
            }
            @media screen and (max-width: 1600px) {
              font-size: 24px;
              margin-bottom: 24px;
            }
          }

          & > div {
            font-size: 18px;
            line-height: 40px;
            color: $fontColor;
            @media screen and (max-width: 1000px) {
              width: 650px;
              font-size: 16px;
            }
            @media screen and (max-width: 1600px) {
              line-height: 32px;
              margin-bottom: 16px;
            }
          }

          & > ul {
            margin-top: 16px;
            display: flex;
            font-size: 16px;
            justify-content: space-between;
            @media screen and (max-width: 1000px) {
              width: 650px;
              font-size: 16px;
            }
            @media screen and (max-width: 1600px) {
              margin-top: 12px;
              margin-bottom: 14px;
            }

            & > li {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-bottom: 5px;

              & > a {
                color: #27a3cf;
                text-align: center;
              }

              &:nth-of-type(1) {
                & > a {
                  color: #666666;
                  cursor: text;
                }
              }
            }
          }

          & > img {
            width: 100%;
          }
        }

        & > img {
          width: 50% !important;
          height: auto !important;
          margin: 0 25%;
        }

        @media screen and (max-width: 1600px) {
          & > img {
            width: 70% !important;
            height: auto !important;
            margin: 0 15%;
          }
        }
      }

      .news-detail {
        width: 70%;
        margin: 0 auto;

        & > p {
          text-indent: 2em;
          font-size: 18px;
          line-height: 48px;

          &:nth-of-type(1) {
            margin-top: 114px;
          }

          &:nth-last-of-type(1) {
            margin-bottom: 150px;
          }

          & > img {
            width: 100% !important;
            height: auto !important;
            margin: 36px 0;
          }
        }

        & > img {
          width: 100% !important;
          height: auto !important;
          margin: 36px 0;
        }
      }

      .news-footer {
        width: 70%;
        margin: 0 auto 56px auto;
        display: flex;
        justify-content: space-between;

        .news-footer-left {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          & > span {
            display: inline-block;

            &:nth-of-type(1) {
              cursor: pointer;
              color: $color;
            }

            &:nth-of-type(2) {
              margin: 0 12px 0 38px;
              color: #666666;
            }

            &:nth-of-type(3) {
              color: #666666;
            }
          }
        }

        .news-footer-right {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          & > span {
            display: inline-block;
            cursor: pointer;

            &:nth-of-type(2) {
              width: 51px;
              height: 51px;
              margin: 0 26px 0 15px;
              background: #fff url(/static/airice/images/news/sina.png) 0 0 no-repeat;
            }

            &:nth-of-type(3) {
              width: 51px;
              height: 51px;
              background: #fff url(/static/airice/images/news/wx.png) 0 0 no-repeat;
            }
          }

          .share-code {
            display: none;
            position: absolute;
            right: -120px;
            top: -30px;
          }
        }
      }

      background-color: #fff;
    }

  }

  &.screen2 {
    //background: linear-gradient(0deg, #41b93e 0%, #3b9e3c 35%, #274535 75%, #190530 100%);
  }

  &.screen3 {
    background: linear-gradient(0deg, #395ecb 0%, #3657be 35%, #242161 75%, #1a0531 100%);
  }

  &.screen4 {
    background: url(/static/airice/images/bg.png) no-repeat center 200px, linear-gradient(0deg, #ff9f42 0%, #ed923d 35%, #6d3816 75%, #2e0b04 100%);
  }

  &.screen5 {
    background: url(/static/airice/images/bg.png) no-repeat center -300px, linear-gradient(0deg, #d74d53 0%, #c44655 35%, #4c184c 75%, #110145 100%);
  }
}

/*
*头部标题
*/
.g-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%);

  .navbar-inverse {
    background-color: rgba(255, 255, 255, 0);
  }

  .g-title {
    .navbar-header {
      position: relative;
      top: 25px;

      .g-logo {
        position: absolute;
        background-image: url(/static/airice/images/logo.png);
        width: 164px;
        height: 54px;
        top: 0;
        left: 130px;
        -webkit-background-size: cover;
        background-size: cover;
        @media screen and (max-width: 1600px) {
          -webkit-background-size: 70%;
          background-size: 70%;
          background-repeat: no-repeat;
          background-position: center 0;
          left: 200px;
          top: 10px;
        }
        @media screen and (min-width: 1000px) and (max-width: 1200px) {
          -webkit-background-size: 70%;
          background-size: 70%;
          background-repeat: no-repeat;
          background-position: center 0;
          left: 110px;
          top: 10px;
        }
        @media screen and (min-width: 768px) and (max-width: 1000px) {
          -webkit-background-size: 70%;
          background-size: 70%;
          background-repeat: no-repeat;
          background-position: center 0;
          left: 10px;
          top: 10px;
        }
        @media screen and (max-width: 768px) {
          -webkit-background-size: 70%;
          background-size: 70%;
          background-repeat: no-repeat;
          background-position: center 0;
          left: 50%;
          top: 50%;
          margin: -27px 0 0 -82px;
        }
      }
    }

    .g-nav {
      overflow: hidden;
      @media screen and (min-width: 768px) {
        margin-left: 164px;
      }
      @media screen and (min-width: 768px) {
        display: flex !important;
        justify-content: center;
      }

      & > ul {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        @media screen and(min-width: 990px) {
          margin-left: 164px;
        }
        @media screen and (min-width: 768px) {
          margin-left: -20px;
        }

        & > li {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            height: 16px;
            top: 50%;
            margin-top: -8px;
            border-left: 1px solid #fff;
          }

          &:first-child {
            &::before {
              content: '';
              border: none;
            }
          }

          & > a {
            &.active {
              color: #27a3cf;
            }

            color: #fff;
            font-size: 18px;
            @media screen and (max-width: 1600px) {
              font-size: 16px;
            }
            @media screen and (max-width: 1200px) {
              font-size: 16px;
            }
          }
        }
      }
    }

    .qr-code {
      display: inline-block;
      position: relative;
      top: 26px;
      @media screen and(max-width: 768px) {
        top: 0;
      }
      left: 10px;
      cursor: pointer;
      @media screen and(min-width: 768px) and(max-width: 1000px) {
        left: 0;
      }

      &:before {
        font-size: 36px;
        color: #fff;
      }
    }

    .wx-code {
      display: none;
      width: 100px;
      height: 100px;
      position: absolute;
      top: 17px;
      right: 50px;
      background: url(/static/airice/images/home/qrcode.jpg) 0 0 no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      @media screen and (max-width: 1000px) {
        right: 50px;
      }
    }
  }
}

/*
* 首页  产品中心样式
*/
.product {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  .tab-content {
    display: flex;
    flex: 1;

    &.p-box {
      & > div {
        height: 100%;
        width: 100%;

        & > div {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #000;

          &.p-big {
            color: #fff;
            background-size: cover;

            & > p, & > h3 {
              margin-bottom: 16px;
              font-size: 48px;
              @media screen and (max-width: 1600px) {
                font-size: 36px;
                margin-bottom: 9px;
              }
              font-family: 'English font';

              &.p-text {
                font-size: 32px;
                @media screen and (max-width: 1600px) {
                  font-size: 24px;
                }
                font-family: $main-font;
              }
            }

            & > span {
              &.p-introduce {
                width: 550px;
                text-align: center;
                font-size: 24px;
                @media screen and (max-width: 1600px) {
                  font-size: 18px;
                }
              }
            }

            .p-left-caption {
              width: 430px;
              position: absolute;
              left: 155px;
              top: 92px;
              @media screen and (max-width: 1600px) {
                left: 100px;
                top: 70px;
              }

              h3 {
                font-family: "English font";
                font-size: 48px;
                @media screen and (max-width: 1600px) {
                  font-size: 36px;
                }
                color: $color;
              }

              & > p {
                &:nth-of-type(1) {
                  font-family: $main-font;
                  font-size: 32px;
                  @media screen and (max-width: 1600px) {
                    font-size: 24px;
                    margin: 8px 0 8px 0;
                  }
                  margin: 18px 0 23px 0;
                }

                &:nth-of-type(2) {
                  font-family: "English font";
                  font-size: 20px;
                  @media screen and (max-width: 1600px) {
                    font-size: 16px;
                  }
                  margin-bottom: 42px;
                }
              }

              & > div {
                font-family: $main-font;
                font-size: 18px;
                line-height: 40px;
                text-align: justify;
                @media screen and (max-width: 1600px) {
                  font-size: 14px;
                  max-width: 350px;
                  line-height: 34px;
                }
              }

              & > span {
                &:nth-of-type(1) {
                  position: absolute;
                  left: -90px;
                  top: 130px;
                  display: block;
                  background: url(/static/airice/images/product/line_up.png) 0 0 no-repeat;
                  width: 500px;
                  height: 60px;
                  @media screen and (max-width: 1600px) {
                    top: 89px;
                    -webkit-background-size: 83%;
                    background-size: 83%;
                    left: -70px;
                  }
                }

                &:nth-of-type(2) {
                  position: absolute;
                  right: 0;
                  bottom: -25px;
                  display: block;
                  background: url(/static/airice/images/product/line_down.png) 0 0 no-repeat;
                  width: 429px;
                  height: 16px;
                  @media screen and (max-width: 1600px) {
                    -webkit-background-size: 83%;
                    background-size: 83%;
                  }
                }
              }
            }

            .t-merge-center {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 230px;

              & > span {
                display: inline-block;
                position: relative;
                left: -14px;
                width: 34px;
                height: 34px;
                background: url(/static/airice/images/tech/small_circle.png) 0 0 no-repeat;
                -webkit-background-size: cover;
                background-size: cover;
                @media screen and (max-width: 1600px) {
                  -webkit-background-size: 80%;
                  background-size: 80%;
                  top: 5px;
                  left: -3px;
                }
              }

              & > p {
                font-size: 32px;
                @media screen and (max-width: 1600px) {
                  font-size: 24px;
                }
              }
            }
          }
        }

        &.g-duck {
          background-color: #000;

          & > div {
            display: flex;
            padding-top: 0;

            .p-text {
              display: flex;
              height: 80px;
              line-height: 80px;
              margin-bottom: 0;
              margin-top: 18px;
              background-image: url(/static/airice/images/product/product_big.jpg);
              @media screen and (max-width: 1400px) {
                height: 80px;
                line-height: 80px;
              }
            }

            .g-duck-box {
              max-width: 1353px;
              width: 100%;
              display: flex;
              flex: 1;
              -webkit-background-size: 85%;
              background-size: 85%;
              background-repeat: no-repeat;
              background-position: center -120px;
              background-image: url(/static/airice/images/product/product_big.jpg);
              @media screen and (max-width: 1400px) {
                max-width: 1000px;
                -webkit-background-size: cover;
                background-size: cover;
                background-position: center -50px;
                background-image: url(/static/airice/images/product/product_small.jpg);
              }
              @media screen and (max-width: 1000px) {
                max-width: 800px;
                -webkit-background-size: 100%;
                background-size: 100%;
                background-position: center 50px;
                background-image: url(/static/airice/images/product/product_small.jpg);
              }
            }
          }
        }
      }
    }
  }

  .nav {
    height: 205px;
    display: flex;

    & > li {
      display: flex;
      flex: 1;
      background-size: cover;
      background-position: center center;

      & > a {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        &:focus {
          background-color: transparent;
        }

        &:hover {
          background-color: transparent;
        }

        color: #fff;

        & > p {
          font-size: 24px;
          @media screen and (max-width: 1600px) {
            font-size: 18px;
          }
          transition: 2s;
          margin-bottom: 9px;
          font-family: "English font";

          &.en {
            margin-bottom: -1px;
          }
        }

        & > span {
          font-size: 20px;
          @media screen and (max-width: 1600px) {
            font-size: 12px;
          }
          transition: 2s;

          &.p-explain {
            margin: 0 auto;
            font-size: 14px;
            letter-spacing: 2px;
            width: 60%;
            @media screen and (max-width: 1600px) {
              font-size: 12px;
              width: 50%;
            }

          }
        }
      }

      &:hover {
        & > a {
          & > p {
            transition: all 2s;
            transform: scale(1.1, 1.1);
          }

          & > span {
            transition: all 2s;
            transform: scale(1.1, 1.1);
          }
        }
      }
    }
  }

  .p-product {
    .tab-pane {
      .p-big {
        justify-content: flex-start;
        padding-top: 36px;
        font-size: 36px;

        p {
          font-size: 36px;
        }
      }
    }
  }
}

/*
* 首页  产品中心样式
*/
.about {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  .tab-content {
    width: 100%;

    &.p-box {
      & > div {
        height: 100%;
        width: 100%;

        & > div {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #000;

          &.p-big {
            color: #fff;
            background-size: cover;

            & > h3, & > p {
              margin-bottom: 16px;
              font-size: 55px;
              font-family: 'English font';

              &.p-text {
                font-size: 36px;
                font-family: $main-font;
              }
            }

            & > span {
              &.p-introduce {
                width: 550px;
                text-align: center;
                font-size: 30px;
              }
            }

            .p-left-caption {
              width: 430px;
              position: absolute;
              left: 155px;
              top: 50px;

              h3 {
                font-family: "English font";
                font-size: 48px;
                color: $color;
              }

              & > p {
                &:nth-of-type(1) {
                  font-family: $main-font;
                  font-size: 36px;
                  margin: 18px 0 23px 0;
                }

                &:nth-of-type(2) {
                  font-family: "English font";
                  font-size: 20px;
                  margin-bottom: 42px;
                }
              }

              & > div {
                font-family: $main-font;
                font-size: 24px;
                line-height: 40px;
              }

              & > span {
                &:nth-of-type(1) {
                  position: absolute;
                  left: -90px;
                  top: 130px;
                  display: block;
                  background: url(/static/airice/images/product/line_up.png) 0 0 no-repeat;
                  width: 500px;
                  height: 60px;
                }

                &:nth-of-type(2) {
                  position: absolute;
                  right: 0;
                  bottom: -20px;
                  display: block;
                  background: url(/static/airice/images/product/line_down.png) 0 0 no-repeat;
                  width: 429px;
                  height: 16px;
                }
              }
            }

            .t-merge-center {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 230px;
              margin-left: -115px;

              & > span {
                display: inline-block;
                position: relative;
                left: -14px;
                width: 34px;
                height: 34px;
                background: url(/static/airice/images/tech/small_circle.png) 0 0 no-repeat;
                @media screen and (max-width: 1600px) {
                  -webkit-background-size: 80%;
                  background-size: 80%;
                  top: 5px;
                  left: -3px;
                }
              }

              & > p {
                font-size: 48px;
              }
            }
          }
        }
      }
    }

    &.about-box {
      .tab-pane {
        width: 100%;

        .about-content {
          width: 100%;
          height: 1080px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          @media screen and (max-width: 1440px) {
            height: 768px;
          }

          & > h3 {
            color: #fff;
            font-size: 32px;
            margin-bottom: 56px;
            @media screen and (max-width: 1600px) {
              font-size: 24px;
            }
          }

          & > p {
            width: 60%;
            line-height: 48px;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            text-align: justify;
            @media screen and (max-width: 1600px) {
              font-size: 16px;
            }
          }

          .carousel {
            width: 100%;
            height: 100%;

            .carousel-inner {
              width: 100%;
              height: 100%;

              .item {
                width: 100%;
                height: 100%;
                position: relative;

                & > p {
                  width: 100%;
                  height: 100%;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: cover;
                }

                & > img {
                  height: 100%;
                }

                .about-profile-bg {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  width: 1280px;
                  height: 574px;
                  margin: -297px 0 0 -640px;
                  background: url(/static/airice/images/about/about_small.jpg) 0 1px no-repeat;

                  & > span {
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    top: -113px;
                    width: 500px;
                    height: 687px;
                    @media screen and (max-width: 1440px) {
                      right: -52px;
                      top: -10px;
                      -webkit-background-size: 90% !important;
                      background-size: 90% !important;
                    }
                  }

                  & > div {
                    margin-left: 169px;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    color: #fff;

                    & > h2 {
                      margin-bottom: 32px;
                      font-size: 67px;
                    }

                    & > h3 {
                      margin-bottom: 32px;
                      font-size: 52px;
                    }

                    & > p {
                      font-size: 24px;
                      line-height: 46px;
                    }

                    @media screen and (max-width: 1440px) {
                      & > h2 {
                        font-size: 60px;
                      }

                      & > h3 {
                        font-size: 45px;
                      }

                      & > p {
                        font-size: 22px;
                      }
                    }
                  }
                }

                &:nth-of-type(2) {
                  .about-profile-bg {
                    & > span {
                      top: -98px;
                        @media screen and (max-width: 1440px) {
                            top: 0;
                            right: -50px;
                            -webkit-background-size: 90% !important;
                            background-size: 90% !important;
                        }
                    }
                  }
                }

                .about-profile-list {
                  height: 100%;
                  width: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;

                  & > ul {
                    height: 100%;
                    width: 87%;
                    display: flex;
                    justify-content: space-around;
                    margin: 0 auto;

                    & > li {
                      position: relative;
                      width: 307px;
                      height: 536px;
                      margin-top: 420px;
                      @media screen and (max-width: 1440px) {
                         margin-top: 275px;
                      }
                      .about-li-img {
                        width: 308px;
                        height: 308px;
                        border-radius: 50%;
                        position: absolute;
                        top: -154px;
                        left: 0;
                        background: -webkit-linear-gradient(bottom, #1598ee, #372be4); /* Safari 5.1 - 6.0 */
                        background: -o-linear-gradient(bottom, #1598ee, #372be4); /* Opera 11.1 - 12.0 */
                        background: -moz-linear-gradient(bottom, #1598ee, #372be4); /* Firefox 3.6 - 15 */
                        background: linear-gradient(to bottom, #1598ee, #372be4); /* 标准的语法（必须放在最后） */
                      }

                      .about-li-bg {
                        height: 536px;
                        width: 307px;
                        background-color: #0c1d33;

                        & > div {
                          width: 255px;
                          height: 215px;
                          position: absolute;
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          left: 50%;
                          top: 208px;
                          margin-left: -128px;
                          background: url(/static/airice/images/about/about_text.png) 0 0 no-repeat;

                          & > h3 {
                            color: #fff;
                            margin: 9px 0 10px 0;
                          }

                          & > p {
                            color: #b9bcbf;
                            line-height: 24px;

                            &:nth-of-type(1) {
                              margin-bottom: 5px;
                            }
                          }
                        }
                      }

                      @media screen and (max-width: 1600px) {
                        width: 218px;
                        height: 381px;
                        .about-li-img {
                          width: 218px;
                          height: 218px;
                          border-radius: 50%;
                          position: absolute;
                          top: -114px;
                          left: 0;
                          background: -webkit-linear-gradient(bottom, #1598ee, #372be4); /* Safari 5.1 - 6.0 */
                          background: -o-linear-gradient(bottom, #1598ee, #372be4); /* Opera 11.1 - 12.0 */
                          background: -moz-linear-gradient(bottom, #1598ee, #372be4); /* Firefox 3.6 - 15 */
                          background: linear-gradient(to bottom, #1598ee, #372be4); /* 标准的语法（必须放在最后） */
                        }
                        .about-li-bg {
                          height: 318px;
                          width: 218px;
                          background-color: #0c1d33;

                          & > div {
                            width: 181px;
                            height: 153px;
                            position: absolute;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            left: 50%;
                            top: 120px;
                            margin-left: -90px;
                            background: url(/static/airice/images/about/about_text1.png) 0 0 no-repeat;

                            & > h3 {
                              color: #fff;
                              margin: 9px 0 10px 0;
                              font-size: 20px;
                            }

                            & > p {
                              color: #b9bcbf;
                              line-height: 18px;

                              &:nth-of-type(1) {
                                margin-bottom: 5px;
                              }
                            }
                          }
                        }
                      }
                    }

                  }
                }

                .about-profile-item {
                  height: 100%;
                  width: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;
                  & > ul {
                    height: 100%;
                    width: 1440px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin: 0 auto;

                    & > li {
                      position: relative;
                      width: 100%;
                      height: 157px;
                      display: flex;
                      margin-bottom: 12px;
                      box-sizing: border-box;
                     @media screen and (max-width: 1440px) {
                          align-items: center;
                      }
                      .about-item-li {
                        display: flex;
                        align-items: center;
                        width: 1000px;
                        height: 100%;
                        background-color: rgba(0, 0, 0, .67);
                        @media screen and (max-width: 1440px) {
                           height: 80%;
                        }
                        .about-item-bg {
                          width: 157px;
                          height: 157px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          background: url(/static/airice/images/about/about_act.png) 0 0 no-repeat;
                           @media screen and (max-width: 1440px) {
                            width: 114px;
                            height: 114px;
                             -webkit-background-size: 100% 100%;
                             background-size: 100% 100%;
                             &>img{
                               max-width: 88%;
                             }
                           }
                        }

                        & > h3 {
                          font-size: 48px;
                          margin-left: 139px;
                          margin-right: 48px;
                          color: #fff;
                           @media screen and (max-width: 1440px) {
                             font-size: 32px;
                           }
                        }

                        & > p {
                          font-size: 30px;
                          color: #fff;
                          @media screen and (max-width: 1440px) {
                             font-size: 22px;
                           }
                        }

                        & > .about-item-div {
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          width: 420px;
                          color: #d2d4d6;

                          & > p {
                            font-size: 14px;
                            line-height: 18px;
                          }
                        }
                      }

                      &:nth-of-type(1) {
                        padding-right: 110px;
                        flex-direction: column;
                        align-items: flex-end;
                        @media screen and (max-width: 1440px) {
                           justify-content: center;
                        }
                      }

                      &:nth-of-type(2) {
                        .about-item-li {
                          flex-direction: row-reverse;

                          & > h3 {
                            margin-left: 27px;
                            margin-right: 90px;
                          }
                        }
                      }

                      &:nth-of-type(3) {
                        justify-content: center;
                      }

                      &:nth-of-type(4) {
                        flex-direction: row-reverse;

                        .about-item-li {
                          flex-direction: row-reverse;

                          & > h3 {
                            margin-left: 27px;
                            margin-right: 90px;
                          }
                        }
                      }

                      &:nth-of-type(5) {
                        padding-left: 110px;

                        .about-item-li {
                          flex-direction: row-reverse;

                          & > h3 {
                            margin-left: 40px;
                            margin-right: 45px;
                          }
                        }
                      }

                      @media screen and (min-width: 1200px) and (max-width: 1600px) {
                        &:nth-of-type(1) {
                          margin-left: -150px;
                        }
                        &:nth-of-type(2) {
                          margin-left: 50px;
                        }
                        &:nth-of-type(4) {
                          margin-left: -150px;
                        }
                        &:nth-of-type(5) {
                          margin-left: 50px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .development-content {
          max-width: 100%;
          width: 100%;
          height: 100%;
          background-size: 100% 100%;
          background-image: url(/static/airice/images/about/about_31.jpg);
          background-position: center center;
          background-repeat: no-repeat;
          @media screen and (max-width: 1600px) {
            background-image: url(/static/airice/images/about/about_32.jpg);
          }
        }

        &.tab-join {
          background-color: #fff;
        }

        .g-join {
          width: 80%;
          margin: 0 auto;

          & > ul {
            & > li {
              border-bottom: 1px solid #cccccc;
              padding-bottom: 76px;

              &:nth-of-type(1) {
                border-top: 1px solid #666666;
              }

              & > h3 {
                padding-top: 108px;
                font-family: "fangz font";
                font-size: 48px;
                color: $color;
                text-align: center;
              }

              & > h4 {
                padding-top: 76px;
                font-size: 32px;
                font-weight: bold;
                @media screen and (max-width: 1600px) {
                  font-size: 24px;
                }
              }

              & > span {
                display: inline-block;
                font-size: 22px;
                color: $color;
                margin: 44px 0 5px 0;
                @media screen and (max-width: 1600px) {
                  font-size: 18px;
                }

                &:nth-of-type(2) {
                  margin-top: 22px;
                }
              }

              & > div {
                font-size: 18px;
                color: $fontColor;
                @media screen and (max-width: 1600px) {
                  font-size: 16px;
                }
              }

              & > div {
                &.text-em {
                  text-indent: -3rem;
                  margin-left: 3rem;
                }

                & > ol > li {
                  font-size: 22px;
                  color: $fontColor;
                  line-height: 40px;
                  text-indent: -3rem;
                  margin-left: 3rem;
                  @media screen and (max-width: 1600px) {
                    font-size: 16px;
                  }
                }
              }
            }
          }

          & > div {
            padding-bottom: 94px;
            border-bottom: 1px solid #666;

            & > h4 {
              padding: 76px 0 52px 0;
              font-size: 32px;
              font-weight: bold;
              @media screen and (max-width: 1600px) {
                font-size: 24px;
              }
            }

            & > ul {
              & > li {
                & > span {
                  font-size: 18px;
                  line-height: 48px;
                  color: $fontColor;
                  @media screen and (max-width: 1600px) {
                    font-size: 16px;
                  }

                  &:nth-of-type(1) {
                    color: $color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .about-tab-bg {
    background-color: #fff;

    .nav {
      height: 164px;
      width: 80%;
      margin: 0 auto;
      display: flex;
      background-color: #ffffff;

      & > li {
        display: flex;
        flex: 1;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        & > a {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          text-align: center;

          &:focus {
            background-color: transparent;
          }

          &:hover {
            background-color: transparent;
          }

          color: #fff;

          & > p {
            font-size: 24px;
            margin-bottom: 9px;
            color: #000;
            @media screen and (max-width: 1600px) {
              font-size: 20px;
            }
          }

          & > span {
            font-size: 18px;
            color: #000;
            @media screen and (max-width: 1600px) {
              font-size: 14px;
            }
          }
        }

        &.active {
          & > a {
            & > p {
              color: $color;
            }

            & > span {
              color: $color;
            }
          }
        }
      }
    }
  }
}

/*
 **左右指示器
 */
.g-right-indicator {
  background: url(/static/airice/images/right.png) 0 0 no-repeat;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  margin-left: -25px;
  -webkit-background-size: 100%;
  background-size: 100%;

}

.g-left-indicator {
  background: url(/static/airice/images/right.png) 0 0 no-repeat;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  margin-left: -25px;
  -webkit-background-size: 100%;
  background-size: 100%;
  transform: rotate(-180deg);
}

/*
*案例背景
*/
.g-case-title, .g-tech-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: #fff;
  -webkit-background-size: cover;
  background-size: cover;

  & > h3 {
    margin-bottom: 16px;
    font-size: 48px;
    @media screen and (max-width: 1600px) {
      font-size: 36px;
      margin-bottom: 9px;
    }
    font-family: 'English font';
  }

  & > span {
    font-size: 24px;
    @media screen and (max-width: 1600px) {
      font-size: 18px;
    }

    &.p-introduce {
      width: 550px;
      text-align: center;
      font-size: 24px;
    }
  }
}

/*
    技术平台背景
*/
.g-tech-bg {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: #fff;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;

  .t-merge-circle {
    background: url(/static/airice/images/tech/big_circle.png) 0 0 no-repeat;
    width: 1013px;
    height: 381px;
    position: relative;
    top: -12%;
    //left: -2%;
    @media screen and (max-width: 1600px) {
      width: 663px;
      height: 254px;
      background: url(/static/airice/images/tech/big_circle2.png) center center no-repeat;
    }

    .t-merge-text {
      position: relative;
      height: 100%;
      width: 100%;

      & > span {
        position: absolute;
        font-size: 20px;

        &:nth-of-type(1) {
          left: 365px;
          bottom: -30px;
        }

        &:nth-of-type(2) {
          left: 70px;
          bottom: 28px;
        }

        &:nth-of-type(3) {
          left: -75px;
          bottom: 192px;
        }

        &:nth-of-type(4) {
          left: 150px;
          top: 5px;
        }

        &:nth-of-type(5) {
          left: 492px;
          top: -35px;
        }

        &:nth-of-type(6) {
          left: 830px;
          top: 10px;
        }

        &:nth-of-type(7) {
          right: -85px;
          bottom: 145px;
        }

        &:nth-of-type(8) {
          right: 190px;
          bottom: -10px;
        }

        @media screen and (max-width: 1600px) {
          &:nth-of-type(1) {
            left: 285px;
            bottom: -35px;
          }
          &:nth-of-type(2) {
            left: 25px;
            bottom: 20px;
          }
          &:nth-of-type(3) {
            left: -70px;
            bottom: 120px;
          }
          &:nth-of-type(4) {
            left: 70px;
            top: 0;
          }
          &:nth-of-type(5) {
            left: 305px;
            top: -35px;
          }
          &:nth-of-type(6) {
            left: 545px;
            top: 0;
          }
          &:nth-of-type(7) {
            right: -85px;
            bottom: 110px;
          }
          &:nth-of-type(8) {
            right: -3px;
            bottom: 30px;
          }
        }
      }
    }

    .t-merge-center {
      position: absolute;
      left: 50%;
      bottom: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 230px;
      margin-left: -115px;
      @media screen and (max-width: 1200px) {
        bottom: 45%;
      }

      & > span {
        display: inline-block;
        position: relative;
        left: -14px;
        width: 34px;
        height: 34px;
        background: url(/static/airice/images/tech/small_circle.png) 0 0 no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        @media screen and (max-width: 1600px) {
          -webkit-background-size: 80%;
          background-size: 80%;
          top: 5px;
          left: -3px;
        }
      }

      & > p {
        font-size: 32px;
        @media screen and (max-width: 1600px) {
          font-size: 24px;
        }

      }
    }
  }

  .t-merge-explain {
    position: absolute;
    left: 50%;
    bottom: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1000px;
    margin-left: -500px;

    & > span {
      display: inline-block;
      position: absolute;
      left: -30px;
      width: 25px;
      height: 25px;
      top: 3px;
      background: url(/static/airice/images/tech/small_circle.png) 0 0 no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
    }

    & > div {
      line-height: 32px;
      font-size: 18px;
      text-align: center;
    }
  }
}

/*
 *banner 轮播  下方为文字介绍
*/
.g-case-banner {
  //height: 100%;
  @media screen and (min-width: 1400px) {
    height: 968px;
  }

  .carousel {
    height: 100%;

    .carousel-inner {
      height: 100%;

      .item {
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }

        p {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          -webkit-background-size: cover;
          background-size: cover;
          z-index: 999;
        }

        .g-case-comment {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 78px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 20px;
          background-color: rgba(0, 0, 0, 0.8);

          & > a {
            color: #ffffff;
          }
        }

        .g-news-comment {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 170px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.8);
          @media screen and (max-width: 1600px) {
            height: 170px;
          }

          & > p {
            font-size: 20px;
            margin-bottom: 26px;

            & > a {
              color: #fff;
            }
          }

          & > div {
            width: 700px;
            position: relative;
            top: -45px;

            & > span {
              position: relative;
              line-height: 24px;
              font-size: 14px;
              margin-right: 5px;

              &:first-child {
                &:after {
                  content: ". . .";
                  position: relative;
                  left: 3px;
                }
              }

              &:nth-child(2) {
                color: #27a3cf;
                cursor: pointer;

                & > a {
                  color: #27a3cf;
                  cursor: pointer;
                }
              }
            }
          }
        }

        .g-case-text {
          & > p {
            font-size: 20px;
            margin-bottom: 8px;
            height: auto;
          }

          & > div {
            width: 700px;
            position: relative;
            top: 0;
            text-align: center;

            & > span {
              position: relative;
              line-height: 24px;
              font-size: 14px;
              margin-right: 5px;

              &:first-child {
                &:after {
                  content: "";
                  position: relative;
                  left: 3px;
                }
              }

            }
          }
        }
      }
    }
  }
}


/*
  *尾部
*/
//产业中心  服务
.p-service-explain {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  background-position: center 0;
  background-repeat: no-repeat;
  filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";

  & > p {
    font-size: 32px;
    margin-bottom: 23px;
    color: #fff;
  }

  & > div {
    width: 46%;
    line-height: 42px;
    font-size: 18px;
    color: #fff;
    text-align: center;
  }

  & > button {
    width: 280px;
    height: 54px;
    margin-top: 42px;
    font-size: 24px;
    letter-spacing: 6px;
    color: #fff;
    background-color: #1e9ee1;
    border-radius: 32px;

    &:hover {
      background-color: rgba(30, 158, 225, .8);
    }
  }

  & > .p-solution-text {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 800px;
    // width: 40%;
    height: 225px;
    padding: 25px 15px;
    box-sizing: border-box;
    border: 1px solid #000;
    border-radius: 20px;
    background-color: #fff;

    & > p {
      color: #000;
      font-size: 48px;
      text-align: center;
      margin-bottom: 10px;
    }

    & > span {
      display: inline-block;
      line-height: 40px;
      font-size: 24px;
      color: #000;
    }
  }
}

/*
  蒙版
*/
.small_shadow {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  pointer-events: none;
}

/*
*分页样式居中
*/
.g-pagination {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #bfbfbf;

  & > ul {
    display: flex;

    & > li {
      width: 57px;
      height: 57px;

      &:nth-of-type(1) {
        & > a {
          display: inline-block;
          font-size: 30px;
          text-align: center;
          line-height: 50px;
          padding: 0;
        }

        & > span {
          display: inline-block;
          font-size: 30px;
          text-align: center;
          line-height: 50px;
          padding: 0;
        }
      }

      &:nth-last-of-type(1) {
        & > a {
          display: inline-block;
          font-size: 30px;
          text-align: center;
          line-height: 50px;
          padding: 0;
        }

        & > span {
          display: inline-block;
          font-size: 30px;
          text-align: center;
          line-height: 50px;
          padding: 0;
        }
      }

      & > a {
        width: 57px;
        height: 57px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & > span {
        width: 57px;
        height: 57px;
        display: inline-block;
        line-height: 57px;
        text-align: center;
        padding: 0;
      }
    }
  }

}

/*
  服务案例
*/
.g-case {
  overflow: scroll;

  .g-product {
    height: 683px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .tab-content {
      .tab-pane {
        .p-big {
          color: #fff;
          & > h3 {
            margin-bottom: 16px;
            font-size: 48px;
            font-family: 'English font';
            @media screen and (max-width: 1600px) {
              font-size: 36px;
            }
          }
        }
      }
    }
  }
}

.g-screen5 {
  position: relative;
  height: 300px;
  box-sizing: border-box;
  background-color: #fff !important;
  z-index: 99;
  padding-bottom: 100px;

  & > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 51px auto 0;

    & > li {
      width: 120px;
      margin-left: 15px;

      h3 {
        margin-bottom: 18px;
        font-size: 18px;
      }

      & > ol {
        & > li {
          margin-bottom: 14px;

          & > a {
            font-size: 14px;
            color: $fontColor;
          }
        }
      }

      .wx-code {
        display: block;
        width: 100px;
        height: 100px;
        background: url(/static/airice/images/home/qrcode.jpg) 0 0 no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        position: relative;
        top: -5px;

        &::after {
          content: '扫一扫, 关注公众号';
          position: absolute;
          width: 110px;
          bottom: -20px;
          font-size: 12px;
        }
      }
    }
  }
}

.footer {
  position: relative;
  padding-bottom: 100px;
}

/*
  **滚屏指示器
*/
.gps {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 40%;
  right: 54px;
  margin-top: -80px;

  & > li {
    width: 17px;
    height: 17px;
    background-color: rgba(255, 255, 255, .5);
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 16px;

    &.current {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}


