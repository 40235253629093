
@font-face {font-family: "iconfont";
  src: url('../font/iconfont.eot?t=1545293639998'); /* IE9*/
  src: url('../font/iconfont.eot?t=1545293639998#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAS8AAsAAAAABywAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADMAAABCsP6z7U9TLzIAAAE8AAAARAAAAFY8wkg+Y21hcAAAAYAAAABLAAABcOd0to5nbHlmAAABzAAAAQsAAAE4oThmfGhlYWQAAALYAAAALwAAADYToiR6aGhlYQAAAwgAAAAcAAAAJAfeA4NobXR4AAADJAAAAAgAAAAICAAAAGxvY2EAAAMsAAAABgAAAAYAnAAAbWF4cAAAAzQAAAAgAAAAIAEUAHFuYW1lAAADVAAAAUUAAAJtPlT+fXBvc3QAAAScAAAAHwAAADDh5GqAeJxjYGRgYOBikGPQYWB0cfMJYeBgYGGAAJAMY05meiJQDMoDyrGAaQ4gZoOIAgCKIwNPAHicY2BkYWCcwMDKwMHUyXSGgYGhH0IzvmYwYuRgYGBiYGVmwAoC0lxTGBye+TzzYW7438AQw9zA0AAUZgTJAQDpBQx7eJxjYGBgZWBgYAZiHSBmYWBgDGFgZAABP6AoI1icmYELLM7CoARWwwISf+bz/z+MBPJZwCQDIxvDKOABkzJQHjisIJiBEQDiwgqZAHicY+BgYPi/nfkgswUDN4M4gx6DOYMDQwBDFEMKAwOrHqO6HaO5HKM4HyMzHyM7kAHk6jEyG4kDZYACImLi7ObiYsZG5mqmdozqRmIibEpqJmbsIALEYYUoA+ozMxcHGqUOkjAGyRjDWUyM+/6ysv7dByG3f2Zl/bx92xcWli8B3FwCFUHR1Ux6GqLeakIiXFxsjOmqPPz8ksLCXbzCwpL8/IxHmLK8/dOZmNL9FRStBRS1PnPw8nLw8vFlQWkPlr/79/1lYfm7b/9f5iyWL9tg5v+bzugcwgjUp+Mp66jGzsIoJu6ZaMMvwQ9EZhBqrVMkE1Okk3MEEyOXopmkvQOvIC8cMQAApABAiwB4nGNgZGBgAOJnU67mxPPbfGXgZmEAgRuOBe4I+v92FgZmCyCXg4EJJAoANZ0KCwB4nGNgZGBgbvjfwBDDwgACQJKRARUwAQBHCAJrBAAAAAQAAAAAAAAAAJwAAAABAAAAAgBlAAgAAAAAAAIAAAAKAAoAAAD/AAAAAAAAeJxlj01OwzAQhV/6B6QSqqhgh+QFYgEo/RGrblhUavdddN+mTpsqiSPHrdQDcB6OwAk4AtyAO/BIJ5s2lsffvHljTwDc4Acejt8t95E9XDI7cg0XuBeuU38QbpBfhJto41W4Rf1N2MczpsJtdGF5g9e4YvaEd2EPHXwI13CNT+E69S/hBvlbuIk7/Aq30PHqwj7mXle4jUcv9sdWL5xeqeVBxaHJIpM5v4KZXu+Sha3S6pxrW8QmU4OgX0lTnWlb3VPs10PnIhVZk6oJqzpJjMqt2erQBRvn8lGvF4kehCblWGP+tsYCjnEFhSUOjDFCGGSIyujoO1Vm9K+xQ8Jee1Y9zed0WxTU/3OFAQL0z1xTurLSeTpPgT1fG1J1dCtuy56UNJFezUkSskJe1rZUQuoBNmVXjhF6XNGJPyhnSP8ACVpuyAAAAHicY2BigAAuBuyAiZGJkZmBrTw1syIzj4EBABD0ArAA') format('woff'),
  url('../font/iconfont.ttf?t=1545293639998') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('../font/iconfont.svg?t=1545293639998#iconfont') format('svg'); /* iOS 4.1- */
}

[class^="icon-"], [class*=" icon-"]{
  font-family:"iconfont" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-wx:before { content: "\e64c"; }

@font-face{
  font-family: 'English font';
  src : url('../font/GOTHIC.TTF');
}
@font-face{
  font-family: 'fangz font';
  src : url('../font/fangz.ttf');
}
